import React from "react";
import "./Home.css";
import PlayerLogin from "./gamePages/PlayerLogin";
import quizLogo from "./images/quizLogo.png";

import quizPair from "./images/pair_logo.png";
import geekGameLogo from "./images/geekGameWithoutTheArc.png";
import { NavLink } from "react-router-dom";

class Home extends React.Component {
    render() {
        return (
            <div className="body">
                <header className="header" style={{ backgroundColor: "#00BDF2" }}>
                    <img style={{ float: "left" }} src={geekGameLogo} alt="Quiz logo"></img>
                    {/* <button
                        className="button-27"
                    >
                        <NavLink style={{ color: "white" }} to="./adminlogin">
                            Admin login
                        </NavLink>
                    </button> */}
                    <a className="button-27" href="./adminlogin">
                        Admin Login
                    </a>
                </header>
                {/* <hr style={{ borderTopColor: "#EEEEEE" }}></hr> */}
                <div>
                    <div className="centered">
                        <h1 className="h1" style={{ marginTop: "20px", marginBottom: "2%", color: "#002D72" }}>
                            Welcome to Geek Game!
                        </h1>
                        <img src={quizPair} className="quizPlayers" alt="Cartoon geek players"></img>
                        <br></br>

                        <h1 className="h1login" style={{ color: "rgb(0,189,242)" }}>
                            Login to get started!
                        </h1>
                        <PlayerLogin />
                    </div>
                </div>
            </div>
        );
    }
}
export default Home;
