import styled from "styled-components";
import { Table } from "antd";

const TableContainter = styled.div`
    height: 100%;
    width: 100%;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const StyledSpan = styled.span`
    font-weight: bold;
    font-size: large;
    text-decoration: underline;
    margin: 10px;
`;

const TABLE_COLUMNS = [
    {
        title: "Question",
        dataIndex: "question",
        key: "question",
    },
    {
        title: "Submits",
        dataIndex: "submits",
        key: "submits",
    },
    {
        title: "Correct Rate",
        dataIndex: "correctRate",
        key: "correctRate",
    },
    {
        title: "Hint Rate",
        dataIndex: "hintRate",
        key: "hintRate",
    },
    {
        title: "Avg Time (sec)",
        dataIndex: "avgTime",
        key: "avgTime",
    },
];

export default function QuestionsStatsTable({ players, quiz, status }) {
    const calculateQuestionStats = (players) => {
        if (!quiz.questions?.length || !players?.length) return [];
        const questionDetails = [];
        for (let i = 0; i < quiz.questions?.length; ++i) {
            questionDetails.push({
                answers: [],
                hints: [],
                times: [],
                submits: 0,
            });
        }
        for (let player of players) {
            for (let i = 0; i < player.correctAnswers.length; ++i) {
                questionDetails[i].submits++;
                questionDetails[i].answers.push(player.correctAnswers[i]);
                questionDetails[i].hints.push(player.hintsTaken[i]);
                questionDetails[i].times.push(Math.floor(player.timeTakenPerQuestion[i] / 1000));
            }
        }
        const stats = [];
        for (let i = 0; i < questionDetails.length; ++i) {
            stats.push({
                key: i,
                question: i + 1,
                submits: questionDetails[i].submits,
                correctRate: calculateRate(questionDetails[i].answers),
                hintRate: calculateRate(questionDetails[i].hints),
                avgTime: calculateAverage(questionDetails[i].times),
            });
        }
        return stats;
    };

    const calculateRate = (array) => {
        const sum = array.reduce((partialSum, a) => partialSum + a, 0);
        const rate = Math.floor((sum * 100) / array.length);
        return `${isNaN(rate) ? 0 : rate}%`;
    };

    const calculateAverage = (array) => {
        const average = Math.floor(array.reduce((acc, v, i, a) => acc + v / a.length, 0));
        return isNaN(average) ? 0 : average;
    };

    // if (status === "Pending") {
    //     return <h5>Game Pending Activation...</h5>;
    // }

    const dataSource = !status || status === "Pending" ? [] : calculateQuestionStats(players);
    return (
        <TableContainter>
            <StyledSpan>{`${dataSource.length} Questions`}</StyledSpan>
            <Table columns={TABLE_COLUMNS} dataSource={dataSource} scroll={{ y: "37vh" }} />
        </TableContainter>
    );
}
