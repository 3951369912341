import React, { useEffect } from "react";
import { Form, Input, Select, InputNumber } from "antd";
import styled from "styled-components";
import '../../Home.css'

const FlexContainer = styled.div`
    display: flex;
`;

export default function OpenQuestionEditor({ questionIndex, questions, setQuestions, form }) {
    let question = questions[questionIndex];

    useEffect(() => {
        question = questions[questionIndex];
        form.setFieldsValue({
            [`text${questionIndex}`]: question.text,
            [`correctOption${questionIndex}`]: question.correctAnswer,
            [`hint${questionIndex}`]: question.hint,
            [`imagePath${questionIndex}`]: question.imagePath,
            [`imageHeight${questionIndex}`]: question.imageHeight,
            [`imageWidth${questionIndex}`]: question.imageWidth,
        });
    }, [questions, questionIndex]);

    const onQuestionFieldChange = (field, value) => {
        question[field] = value;
        questions[questionIndex] = question;
        setQuestions(questions);
    };

    return (
  <div className="openQuestion">
            <Form.Item
                label="Text"
                name={`text${questionIndex}`}
                value={question.text}
                rules={[{ required: true, message: "Please input Question text!" }]}
            >
                <Input.TextArea
                    style={{ width: "340px", resize: "none" }}
                    onChange={(e) => onQuestionFieldChange("text", e.target.value)}
                />
            </Form.Item>

            <Form.Item
                label="Correct Answer"
                name={`correctOption${questionIndex}`}
                value={question.correctAnswer}
                rules={[{ required: true }]}
            >
                <Input
                    style={{ width: "250px", height: "30px" }}
                    onChange={(e) => onQuestionFieldChange("correctAnswer", e.target.value)}
                />
            </Form.Item>
            <Form.Item
                label="Hint"
                name={`hint${questionIndex}`}
                value={question.hint}
                rules={[{ required: true, message: "Please input hint!" }]}
            >
                <Input
                
                    onChange={(e) => onQuestionFieldChange("hint", e.target.value)}
                />
            </Form.Item>
            <hr />
            <Form.Item label="Image Path" name={`imagePath${questionIndex}`} value={question.imagePath}>
                <Input
            
                    onChange={(e) => onQuestionFieldChange("imagePath", e.target.value)}
                />
            </Form.Item>
            <FlexContainer>
                <Form.Item label="Image Height" name={`imageHeight${questionIndex}`} value={question.imageHeight}>
                    <InputNumber
                        style={{ width: "50px", height: "30px", marginRight: "5px" }}
                        onChange={(newValue) => onQuestionFieldChange("imageHeight", newValue)}
                    />
                </Form.Item>
                <Form.Item label="Image Width" name={`imageWidth${questionIndex}`} value={question.imageWidth}>
                    <InputNumber
                        style={{ width: "50px", height: "30px", marginRight: "5px" }}
                        onChange={(newValue) => onQuestionFieldChange("imageWidth", newValue)}
                    />
                </Form.Item>
            </FlexContainer>
        </div>
    );
}
