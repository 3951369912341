import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Modal } from "antd";
import { postSignPlayerInWithCode } from "../services/gameApi";
import "antd/dist/reset.css";

export default function PlayerLogin() {
    const [showFailedLoginModal, setShowFailedLoginModal] = useState(false);
    const [failedLoginMessage, setFailedLoginMessage] = useState("");
    const [showTermsModal, setTermsModal] = useState(false);

    const navigate = useNavigate();

    const onFinish = async (values) => {
        const response = await postSignPlayerInWithCode(values.nickname, values.entryCode);
        if (response?.playerId) {
            localStorage.setItem(
                "user",
                JSON.stringify({
                    userId: response.playerId,
                    name: values.nickname,
                    isAdmin: false,
                })
            );
            localStorage.setItem(
                "game",
                JSON.stringify({
                    gameId: response.gameId,
                    quizId: response.quizId,
                })
            );
            setTermsModal(true);
        } else {
            setFailedLoginMessage(response);
            setShowFailedLoginModal(true);
        }
    };

    const onFailedLoginModalClose = () => {
        setShowFailedLoginModal(false);
    };

    useEffect(() => {
        if (localStorage.getItem("user")) navigate("/Playground");
    }, []);

    const onTermsModalOk = () => {
        navigate("/Playground");
    };

    const onTermsModalCancel = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("game");

        setTermsModal(false);
    };

    return (
        <div>
            <Form name="playerLoginForm" style={{ marginBottom: "60px" }} onFinish={onFinish}>
                <Form.Item
                    name="nickname"
                    id="nickname"
                    rules={[
                        {
                            required: true,
                            message: "Nickname is required",
                        },
                    ]}
                >
                    <Input placeholder="Nickname" />
                </Form.Item>
                <Form.Item
                    name="entryCode"
                    id="entryCode"
                    rules={[
                        {
                            required: true,
                            message: "Entry code is required",
                        },
                    ]}
                >
                    <Input placeholder="Entry code" />
                </Form.Item>
                <Button htmlType="submit" className="button-27" style={{ backgroundColor: "#00BDF2" }}>
                    Join Game
                </Button>
            </Form>
            <Modal
                title="Login Failed"
                open={showFailedLoginModal}
                onOk={onFailedLoginModalClose}
                onCancel={onFailedLoginModalClose}
            >
                <p>{failedLoginMessage}</p>
            </Modal>
            <Modal
                title="Terms and Conditions"
                open={showTermsModal}
                onOk={onTermsModalOk}
                onCancel={onTermsModalCancel}
                footer={[<Button onClick={onTermsModalOk}>Ok</Button>]}
            >
                <div style={{ height: 250, overflow: "auto" }}>
                    <ul>
                        <p>
                            <b>
                                By entering this competition, an entrant is indicating his/her agreement to be bound by
                                these terms and conditions.
                            </b>
                        </p>
                        <li>
                            By entering a competition (promotional event) run by Citibank Europe plc (publiczna spółka
                            akcyjna) Oddział w Polsce (“The Promoter”) you agree to the Terms and Conditions below.
                        </li>
                        <li>
                            These Terms and Conditions prevail in the event of any conflict or inconsistency with any
                            other communications or promotional materials.
                        </li>
                        <li>
                            The promoter is Citibank Europe plc (publiczna spółka akcyjna) Oddział w Polsce with its
                            registered seat in Warsaw, at Prosta 36 Street, entered into the register of entrepreneurs
                            maintained by the District Court for Warsaw, XII Commercial Division of the National Court
                            Register, under the number KRS 240467, NIP 1080001260.
                        </li>
                        <li>There is no entry fee and no purchase necessary to enter this competition.</li>
                        <li>
                            Any entries that the Promoter consider (in our absolute discretion) to be dangerous, vulgar,
                            offensive, indecent, illegal racist, copied, offensive, cruel or fraudulent, or infringe on
                            the rights or copyright of others will be immediately disqualified.
                        </li>
                        <li>
                            The promoter reserves the right to cancel or amend the competition and these terms and
                            conditions without notice. Any changes to the competition will be notified to entrants as
                            soon as possible by the promoter.
                        </li>
                        <li>No cash alternative to the prizes will be offered. The prizes are not transferable.</li>
                        <li>
                            The winner will be notified on-site directly after the competition. If the winner cannot be
                            contacted or do not claim the prize, we reserve the right to withdraw the prize from the
                            winner and pick a replacement winner.
                        </li>
                        <li>The promoter will notify the winner when and where the prize can be collected.</li>
                        <li>
                            The prize obtained by a winner is exempted from taxation (covered by the exemption from
                            Personal Income Tax – legal basis: art. 21 section 1 pkt 68a of the Act on Personal Income
                            Tax - the value of gratuitous benefits received from the benefit provider (the Promoter) in
                            connection with the promotion or advertising of the Promoter, the one-off value of the
                            benefit does not exceed PLN 200).
                        </li>
                        <li>
                            Non-Polish residents - Any tax or other charges payable as a result of a prize being awarded
                            or received will be the responsibility of the winner. Winners should seek independent
                            financial advice prior to accepting a prize if this is a concern.
                        </li>
                        <li>
                            The promoter’s decision in respect of all matters to do with the competition will be final
                            and no correspondence will be entered into.
                        </li>
                        <li>
                            By entering this competition, an entrant is indicating his/her agreement to be bound by
                            these terms and conditions.
                        </li>
                        <li>
                            The competition and these terms and conditions will be governed by Polish law and any
                            disputes will be subject to the exclusive jurisdiction of the courts of Poland.
                        </li>
                        <li>
                            The opening and closing dates and times for entries are as indicated in the competition
                            information. Any entries received before the opening and after the closing of the
                            competition will be invalid and will not be entered into the competition.
                        </li>
                        <li>
                            Information, data and the caller line identity (“personal data”) which is provided by
                            entrants when they enter will only be held and used by the Promoter in order to administer
                            this competition.
                        </li>
                        <li>
                            By submitting their personal details, the entrant agrees to the Promoter processing those
                            details in accordance with the purposes made known the entrant at the time of collection and
                            in accordance with Promoter’s privacy policy which can be viewed at
                            http://www.citibank.com/emeaservicecenter/poland/privacy-policy.html
                        </li>
                        <li>
                            The Promoter shall comply with all applicable requirements of the Data Protection Act 2018,
                            the General Data Protection Regulation, any successor legislation or other applicable law.
                        </li>
                        <li>These terms and conditions were last updated on 19 April 2023.</li>
                    </ul>
                </div>
            </Modal>
        </div>
    );
}
