import React, { useState, useEffect } from "react";
import styled from "styled-components";

const QuestionOptionsList = styled.ul`
    margin-left: -40px;
    li {
        text-decoration: none;
        list-style: none;
        color: #2d264b;
        font-size: 16px;
        background: #ffffff;
        border: 1px solid #eaeaea;
        border-radius: 16px;
        padding: 11px;
        margin-top: 15px;
        cursor: pointer;
        display: inline-block;
        width: 90%;
    }
    .selected-answer {
        background: rgb(0, 189, 242);
        border: 1px solid rgb(0, 81, 103);
    }
`;

const OPTION_LETTERS = ["a", "b", "c", "d"];

export default function ClosedQuestionCard({ options, correctAnswer, setIsAnswerCorrect, setIsAnswerProvided }) {
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);

    useEffect(() => {
        setSelectedAnswerIndex(null);
    }, [options]);

    const handleSelect = (index) => {
        setSelectedAnswerIndex(index);
        setIsAnswerProvided(true);
        setIsAnswerCorrect(index == correctAnswer);
    };

    return (
        <QuestionOptionsList>
            {options.map((item, index) => (
                <div style={{ height: 100, display: "inline-block", width: "50%" }}>
                    <li
                        onClick={() => handleSelect(index)}
                        key={item}
                        className={selectedAnswerIndex === index ? "selected-answer" : null}
                    >
                        {`${OPTION_LETTERS[index]}) `}
                        {item}
                    </li>
                </div>
            ))}
        </QuestionOptionsList>
    );
}
