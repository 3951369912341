import { Routes, Route } from "react-router-dom";
import GamesEditMenu from "./GamesEditMenu";
import GameAdminDashboard from "./GameAdminDashboard";

export default function GamesMenu() {
    return (
        <Routes>
            <Route index element={<GamesEditMenu />} />
            <Route path="gameStats/:gameId" element={<GameAdminDashboard />} />
        </Routes>
    );
}
