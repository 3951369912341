import React, { useState } from "react";
import styled from "styled-components";
import HintCard from "./HintCard";
import ClosedQuestionCard from "./ClosedQuestionCard";
import OpenQuestionCard from "./OpenQuestionCard";
import geekGameLogo from "../../images/geek-game-logo-white.png";
const QuestionContainer = styled.div`
    max-width: 40%;
    margin: auto;
    overflow-y: scroll;
    margin-top: 20px;
    background-color: white;
    h3 {
        text-align: center;
    }
    img {
        display: block;
        margin: auto;
    }
        @media only screen and (max-width: 460px) {
        max-width:88%;
    }
`;

const NextButton = styled.button`
    background-color: rgb(0, 189, 242);
    border-radius: 9px;
    font-size: 18px;
    min-height: 30px;
    color: #2d264b;
    display: inline-block;
    float: right;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
    :disabled {
        background: #e7e8e9;
        color: #9fa3a9;
        cursor: not-allowed;
    }
`;

export default function Question({ question, handleClickNext }) {
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
    const [isAnswerProvided, setIsAnswerProvided] = useState(false);
    const [hintTaken, setHintTaken] = useState(false);

    const onClickNext = () => {
        handleClickNext(isAnswerCorrect, hintTaken);
        setIsAnswerCorrect(false);
        setIsAnswerProvided(false);
        setHintTaken(false);
    };

    return (
        <QuestionContainer>
            <h3>{question.text}</h3>
            <img src={question.imagePath} alt="" width={question.imageWidth} height={question.imageHeight} />
            {question.type === "Closed" ? (
                <ClosedQuestionCard
                    options={question.options}
                    correctAnswer={question.correctAnswer}
                    setIsAnswerCorrect={setIsAnswerCorrect}
                    setIsAnswerProvided={setIsAnswerProvided}
                />
            ) : (
                <OpenQuestionCard
                    correctAnswer={question.correctAnswer}
                    setIsAnswerCorrect={setIsAnswerCorrect}
                    setIsAnswerProvided={setIsAnswerProvided}
                />
            )}
            <HintCard hint={question.hint} setHintTaken={setHintTaken} />
            <NextButton onClick={onClickNext} disabled={isAnswerProvided === false}>
                Next
            </NextButton>
        </QuestionContainer>
    );
}
