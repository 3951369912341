import React from "react";
import prize from "../../images/prize.png";
import correctLogo from "../../images/correct.svg";
import wrongLogo from "../../images/wrong.svg";
import hintLogo from "../../images/hint.svg";
import "./Results.css";

export default function Result({ result, timeIsUp, user }) {
    const mainMessage = timeIsUp ? "Time's up! Here are your results" : "Congratulations! You have finished the quiz";
    return (
        <div className="result">
            <img style={{ marginTop: "0" }} src={prize} width="200px" alt="Cup logo"></img>
            <h1>{mainMessage}</h1>
            <h2>Check your place with organizators</h2>
            <h3>Nickname: {user.name}</h3>
            <div className="res">
                <img src={correctLogo} width="25px" alt="Correct logo"></img>
                <p>
                    You've got <span> {result.correctAnswers}</span> correct answers
                </p>
            </div>

            <div className="stats">
                <div className="res">
                    {" "}
                    <img src={wrongLogo} width="20px" alt="Wrong logo"></img>{" "}
                    <p>
                        Wrong Answers:<span> {result.wrongAnswers}</span>
                    </p>
                </div>
                <div className="res">
                    <img src={hintLogo} width="13px" alt="Hint logo"></img>
                    <p>
                        Hints taken: <span> {result.hintsTaken}</span>
                    </p>
                </div>
            </div>
        </div>
    );
}
