import React, { useState, useEffect } from "react";
import "./App.css";
import "antd/dist/reset.css";
import { message } from "antd";
import Home from "./Home";
import Footer from "./Footer";
import AdminLogin from "./adminPages/AdminLogin";
import AdminDashboard from "./adminPages/AdminDashboard";
import { Route, Routes } from "react-router-dom";
import { UserContext } from "./contexts/userContext";
import { MessageContext } from "./contexts/messageContext";
import Playground from "./gamePages/Playground";
import { GameContext } from "./contexts/gameContext";
import Protected from "./Protected";
import AdminLoginService from "./services/AdminLoginService";

function App() {
    const [user, setUser] = useState({});
    const [game, setGame] = useState({});
    const [messageApi, contextHolder] = message.useMessage();
    const sendMessage = (type, message) => {
        if (type === "info") {
            messageApi.info(message);
        } else if (type === "error") {
            messageApi.error(message);
        } else if (type === "success") {
            messageApi.success(message);
        }
    };
    const adminLoginService = new AdminLoginService();
    const parsedToken = adminLoginService.parseJwt(localStorage.getItem("adminToken"));
    const [isAdminSignedIn, setIsAdminSignedIn] = useState(parsedToken ? parsedToken.isAdmin : false);
    const adminSignIn = () => {
        const parsedToken = adminLoginService.parseJwt(localStorage.getItem("adminToken"));
        const isAdmin = parsedToken ? parsedToken.isAdmin : false;
        if (isAdmin) {
            setIsAdminSignedIn(true);
        }
    };
    const signout = () => {
        setIsAdminSignedIn(false);
    };

    return (
        <UserContext.Provider value={{ user, setUser }}>
            <GameContext.Provider value={{ game, setGame }}>
                <MessageContext.Provider value={{ sendMessage }}>
                    {contextHolder}
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/adminlogin" element={<AdminLogin adminSignIn={adminSignIn} />} />
                        <Route path="/adminDashboard/*"  element={
          <Protected isSignedIn={isAdminSignedIn}>
          <AdminDashboard />
          </Protected>} />
                        <Route path="/Playground" element={<Playground />} />
                    </Routes>
                    <Footer></Footer>
                </MessageContext.Provider>
            </GameContext.Provider>
        </UserContext.Provider>
    );
}

export default App;
