import React, { useState, useEffect } from "react";
import { fetchQuizzes } from "../../services/quizApi";
import styled from "styled-components";
import QuizCard from "./QuizCard";

const QuizzesGrid = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    background-color: white;
    flex-wrap: wrap;
`;

export default function QuizzesList({ setSelectedQuiz, setEditMode }) {

    const [quizzes, setQuizzes] = useState([]);

    useEffect(() => {
        fetchQuizzes().then((fetchedQuizzes) => setQuizzes(fetchedQuizzes));
    }, []);

    const startQuizEdit = (quiz) => {
        setSelectedQuiz(quiz);
        setEditMode(true);
    };

    return (
        <QuizzesGrid>
            {quizzes.length ? (
                quizzes.map((quiz) => <QuizCard key={quiz._id} quiz={quiz} startQuizEdit={startQuizEdit} />)
            ) : (
                <h5>No quizzes</h5>
            )}
        </QuizzesGrid>
    );
}
