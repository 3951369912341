import moment from "moment";

export default function CountdownClock({ startedAt, endedAt, status }) {
    switch (status) {
        case "Active":
            return <h5>{`Started at ${moment(startedAt).format("YYYY-MM-DD hh:mm:ss")}`}</h5>;
        case "Closed":
            return <h5>{`Ended at ${moment(endedAt).format("YYYY-MM-DD hh:mm:ss")}`}</h5>;
        default:
            return <></>;
    }
}
