import React from "react";
import styled from "styled-components";
import { PlayCircleTwoTone, PauseCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
`;

const IconWrapper = styled.span`
    margin-right: 5px;
    font-size: 35px;
`;

export default function StatusHeader({ status }) {
    const PendingIcon = <PauseCircleTwoTone twoToneColor="#f0c30e" />;
    const ActiveIcon = <PlayCircleTwoTone twoToneColor="#3cbd45" />;
    const ClosedIcon = <CloseCircleTwoTone twoToneColor="#eb2f96" />;

    const statusToIcon = {
        Pending: PendingIcon,
        Active: ActiveIcon,
        Closed: ClosedIcon,
    };

    return (
        <MainContainer>
            <IconWrapper>{statusToIcon[status]}</IconWrapper>
            {status}
        </MainContainer>
    );
}
