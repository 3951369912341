import React, { useEffect, useState } from "react";
import styled from "styled-components";

const HintDiv = styled.div`
    display: inline-block;
    margin-top: 15px;
    color: #2d264b;
    font-size: 18px;
    background: rgb(0, 189, 242);
    border: none;
    border-radius: 9px;
    cursor: pointer;
    min-height: 30px;
    padding: 11px;
    ${({ flipped }) => flipped && `
        max-width: 100%;
        background: #e7e8e9;
        color: #2d264b;
        cursor: not-allowed;
    `}
`;

export default function HintCard( { hint, setHintTaken }){
    const [flipped, setFlipped] = useState(false);

    useEffect(() => {
        setFlipped(false);
    }, [hint]);

    const handleClick = () => {
        setFlipped(true);
        setHintTaken(true);
    };

    return (
        <HintDiv onClick={handleClick} flipped={flipped}>
            {flipped ? hint : 'Hint'}
        </HintDiv>
    );
}