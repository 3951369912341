import { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchGameDetails } from "../../services/gameApi";
import { Layout } from "antd";
import GameProgressTimer from "./GameAdminViewComponents/GameProgressTimer";
import PlayerStatsTable from "./GameAdminViewComponents/PlayerStatsTable";
import GameChart from "./GameAdminViewComponents/GameChart";
import QuestionsStatsTable from "./GameAdminViewComponents/QuestionsStatsTable";
import GameButtonBar from "./GameAdminViewComponents/GameButtonBar";
import { fetchQuiz } from "../../services/quizApi";

const REFRESH_EVERY_X_SECONDS = 60;

export default function GameAdminDashboard() {
    const { gameId } = useParams();

    const [gameStats, setGameStats] = useState({});
    const [quizData, setQuizData] = useState({});

    const refreshGameDetails = useCallback(async (firstRender) => {
        const gameData = await fetchGameDetails(gameId);
        console.log("gd", gameData);
        setGameStats(gameData);
        if (firstRender) {
            const quizData = await fetchQuiz(gameData.quizId);
            setQuizData(quizData);
        }
    }, []);

    useEffect(() => {
        refreshGameDetails(true);
        setInterval(() => refreshGameDetails(), 1000 * REFRESH_EVERY_X_SECONDS);
    }, [refreshGameDetails]);

    return (
        <Layout>
            <Layout.Header className="admin-sub-menu-header">
                <span>{gameStats.name}</span>
            </Layout.Header>
            <div className="game-admin-main-view">
                <div className="game-admin-thin-row">
                    <GameProgressTimer
                        startedAt={gameStats.startedAt}
                        endedAt={gameStats.endedAt}
                        status={gameStats.status}
                    />
                </div>
                <div className="game-admin-thick-row">
                    <div className="game-admin-thin-column">
                        <PlayerStatsTable players={gameStats.players} status={gameStats.status} />
                    </div>
                    <div className="game-admin-thick-column">
                        <GameChart players={gameStats.players} quiz={quizData} status={gameStats.status} />
                    </div>
                    <div className="game-admin-thin-column">
                        <QuestionsStatsTable players={gameStats.players} quiz={quizData} status={gameStats.status} />
                    </div>
                </div>
                <div className="game-admin-thin-row">
                    <GameButtonBar status={gameStats.status} refreshGameDetails={refreshGameDetails} gameId={gameId} />
                </div>
            </div>
        </Layout>
    );
}
