const IS_DEV = false;

const DEV_HOSTNAME = "172.26.169.173";
const DEV_PORT = "2137";

const API_BASE_PATH = IS_DEV ? `http://${DEV_HOSTNAME}:${DEV_PORT}` : `https://hsta-geek-game-backend.onrender.com`;

export const API_GAME_PATH = `${API_BASE_PATH}/game`;

export const API_QUIZ_PATH = `${API_BASE_PATH}/quizzes`;
