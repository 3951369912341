import { API_QUIZ_PATH } from "./apiEndpoints";

const HEADERS = {
    "Content-Type": "application/json",
};

export async function fetchQuizzes() {
    try {
        const response = await fetch(API_QUIZ_PATH);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e.toString());
        return [];
    }
}

export async function fetchQuiz(id) {
    try {
        const createUrl = `${API_QUIZ_PATH}/${id}`;
        const response = await fetch(createUrl);
        if (response.ok) {
            const jsonResponse = await response.json();
            return jsonResponse;
        }
    } catch (e) {
        console.log(e.toString());
        return false;
    }
}

export async function postCreateQuiz(title, questions) {
    try {
        const createUrl = `${API_QUIZ_PATH}/create`;
        const body = {
            title: title,
            questions: questions,
        };
        const response = await fetch(createUrl, {
            method: "POST",
            headers: HEADERS,
            body: JSON.stringify(body),
        });
        const data = await response.json();
        return data?.status;
    } catch (e) {
        console.log(e.toString());
        return false;
    }
}

export async function postUpdateQuiz(id, title, questions) {
    try {
        const createUrl = `${API_QUIZ_PATH}/update/${id}`;
        const body = {
            title: title,
            questions: questions,
        };
        const response = await fetch(createUrl, {
            method: "POST",
            headers: HEADERS,
            body: JSON.stringify(body),
        });
        const data = await response.json();
        return true;
    } catch (e) {
        console.log(e.toString());
        return false;
    }
}

export async function postDeleteQuiz(id) {
    try {
        const createUrl = `${API_QUIZ_PATH}/delete/${id}`;
        const response = await fetch(createUrl, {
            method: "POST",
            headers: HEADERS,
        });
        const data = await response.json();
        return true;
    } catch (e) {
        console.log(e.toString());
        return false;
    }
}
