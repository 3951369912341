import React, { useState } from "react";
import { Layout, Menu } from "antd";
import GamesMenu from "./components/GamesMenu";
import QuizzesMenu from "./components/QuizzesMenu";

const MENU_ENTRIES = [
    {
        label: "Quizzes",
        key: "quizzes",
    },
    {
        label: "Games",
        key: "games",
    },
];

export default function AdminDashboard() {
    const amIAGameLink = window.location.pathname.includes("game");
    const [currentTab, setCurrentTab] = useState(amIAGameLink ? "games" : "quizzes");

    const onMenuClick = (e) => {
        setCurrentTab(e.key);
    };
    return (
        <Layout>
            <Layout.Header className="admin-dashboard-heading">
                <div className="site-logo">Admin Dashboard</div>
                <Menu
                    theme="dark"
                    onClick={onMenuClick}
                    selectedKeys={[currentTab]}
                    mode="horizontal"
                    items={MENU_ENTRIES}
                />
            </Layout.Header>
            <div style={{ height: "100%", width: "100%" }}>
                {currentTab === "games" ? <GamesMenu /> : <QuizzesMenu />}
            </div>
        </Layout>
    );
}
