import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import styled from "styled-components";
import { postCreateGame, postUpdateGame } from "../../services/gameApi";
import { MessageContext } from "../../contexts/messageContext";
import { fetchQuizzes } from "../../services/quizApi";

const FormContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 125px;
`;

export default function GameEitor({ game, setEditMode }) {
    const [name, setName] = useState("");
    const [quizId, setQuizId] = useState("");
    const [startDate, setStartDate] = useState("03/11/2023");
    const [timeAlloted, setAllotedTime] = useState("");
    const [status, setStatus] = useState("");
    const [entryCode, setEntryCode] = useState("");

    const [showModal, setShowModal] = useState(false);

    const [form] = Form.useForm();

    const { sendMessage } = useContext(MessageContext);

    const [quizzes, setQuizzes] = useState([]);

    const Option = Select.Option;

    useEffect(
        () => {
            if (game) {
                setName(game.name);
                setQuizId(game.quizId);
                setStartDate(game.startDate);
                setAllotedTime(game.timeAlloted);
                setStatus(game.status);
                setEntryCode(game.entryCode);

                form.setFieldsValue({
                    name: game.name,
                    quizId: game.quizId,
                    startDate: game.startDate,
                    timeAlloted: game.timeAlloted,
                    status: game.status,
                    entryCode: game.entryCode,
                });
            }
            fetchQuizzes().then((fetchedQuizzes) => setQuizzes(fetchedQuizzes));
        },
        [game],
        []
    );

    const onFormSubmit = () => {
        setShowModal(true);
    };

    const submitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowModal(false);
        if (game?.name) {
            updateGame(game._id, name, quizId, startDate, timeAlloted, status, entryCode);
        } else {
            createGame(name, quizId, startDate, timeAlloted, entryCode, status);
        }
        setEditMode(false);
    };

    const updateGame = async (id, name, quizId, startDate, timeAlloted, status, entryCode) => {
        const success = await postUpdateGame(id, name, quizId, startDate, timeAlloted, entryCode, status);
        if (success) {
            sendMessage("success", "Quiz saved successfully");
        } else {
            sendMessage("error", "Error while saving quiz");
        }
        window.location.reload();
    };

    const createGame = async (name, quizId, startDate, timeAlloted, entryCode, status) => {
        const success = await postCreateGame(name, quizId, startDate, timeAlloted, entryCode, status);
        if (success) {
            sendMessage("success", "Quiz saved successfully");
        } else {
            sendMessage("error", "Error while saving quiz");
        }
        window.location.reload();
    };

    return (
        <FormContainer>
            <Form form={form} name="GameForm" onFinish={onFormSubmit} layout="horizontal">
                <Form.Item
                    label="Game Title"
                    name="name"
                    value={name}
                    rules={[{ required: true, message: "Please input Game Title!" }]}
                >
                    <Input style={{ height: "40px" }} onChange={(e) => setName(e.target.value)} />
                </Form.Item>

                <Form.Item
                    label="Quiz Id"
                    name="quizId"
                    value={quizId}
                    rules={[{ required: true, message: "Please input Quiz Id!" }]}
                >
                    <Select
                        placeholder="Select quiz"
                        style={{ width: 180 }}
                        value={quizId}
                        onChange={(value) => setQuizId(value)}
                    >
                        {quizzes
                            ? quizzes.map((quiz, index) => {
                                  return (
                                      <Option key={index} value={quiz._id}>
                                          {quiz.title}
                                      </Option>
                                  );
                              })
                            : ""}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Start Date"
                    name="startDate"
                    value={startDate}
                    rules={[{ required: true, message: "Please input start Date!" }]}
                >
                    <Input type="date" style={{ height: "40px" }} onChange={(e) => setStartDate(e.target.value)} />
                </Form.Item>

                <Form.Item
                    label="Alloted time (in minutes)"
                    name="timeAlloted"
                    value={timeAlloted}
                    rules={[{ required: true, message: "Please input allotted time!" }]}
                >
                    <Input style={{ height: "40px" }} onChange={(e) => setAllotedTime(e.target.value)} />
                </Form.Item>

                <Form.Item
                    label="Status"
                    name="status"
                    value={status}
                    rules={[{ required: true, message: "Please input status!" }]}
                >
                    <Select
                        style={{ width: 180 }}
                        placeholder="Select status"
                        value={status}
                        onChange={(value) => setStatus(value)}
                    >
                        <Option value="Pending">Pending</Option>
                        <Option value="Active">Active</Option>
                        <Option value="Closed">Closed</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Game code"
                    name="entryCode"
                    value={entryCode}
                    rules={[{ required: true, message: "Please input entryCode!" }]}
                >
                    <Input style={{ height: "40px" }} onChange={(e) => setEntryCode(e.target.value)} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save Game
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                title={`Confirm ${game ? "Save" : "Update"}`}
                open={showModal}
                onOk={submitForm}
                onCancel={() => setShowModal(false)}
            >
                <p>Are you sure that you want to save game?</p>
            </Modal>
        </FormContainer>
    );
}
