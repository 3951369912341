import React, { useState } from "react";
import { Button, Modal } from "antd";
import styled from "styled-components";
import { activateGame, deactivateGame } from "../../../services/gameApi";

const MainContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: row;
`;

export default function GameButtonBar({ status, refreshGameDetails, gameId }) {
    const [showModal, setShowModal] = useState(false);

    const gameOn = () => {
        setShowModal(false);
        activateGame(gameId);
    };

    const gameOff = () => {
        setShowModal(false);
        deactivateGame(gameId);
    };

    let buttonColor;
    let text;
    switch (status) {
        case "Pending":
            buttonColor = "green";
            text = "Activate";
            break;
        case "Active":
            buttonColor = "red";
            text = "Close";
            break;
        case "Closed":
            buttonColor = "darkgrey";
            text = "Closed";
            break;
    }
    return (
        <MainContainer>
            <Button type="primary" size="large" onClick={refreshGameDetails}>
                Refresh
            </Button>
            <Button
                type="primary"
                size="large"
                style={{ backgroundColor: buttonColor }}
                onClick={() => setShowModal(true)}
            >
                {text}
            </Button>
            <Modal
                title={`Confirm Game ${status === "Pending" ? "Activation" : "Finish"}`}
                open={showModal}
                onOk={status === "Pending" ? gameOn : gameOff}
                onCancel={() => setShowModal(false)}
            >
                <p>{`Are you sure that you want to ${status === "Pending" ? "activate" : "deactivate"} the game?`}</p>
            </Modal>
        </MainContainer>
    );
}
