import styled from "styled-components";

const QuestionCardContainer = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    border-radius: 10px;
    margin: 10px;
    background-color: white;
    @media only screen and (max-width: 450px) {
    width:360px;    
    }
    @media only screen and (max-width: 380px) {
    width:300px;    
    }
}
`;

export default QuestionCardContainer;
