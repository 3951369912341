import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchQuiz } from "../services/quizApi";
import { postSubmitQuestion } from "../services/gameApi";
import geekGameLogo from "../images/geek-game-logo-white.png";
import "../Home.css";
import Result from "./components/Result";
import Question from "./components/Question";

export default function Playground() {
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [activeQuestion, setActiveQuestion] = useState(JSON.parse(localStorage.getItem("activeQuestion")) || 0);
    const [showResult, setShowResult] = useState(JSON.parse(localStorage.getItem("showResult")) || false);
    const [result, setResult] = useState(
        JSON.parse(localStorage.getItem("result")) || {
            correctAnswers: 0,
            wrongAnswers: 0,
            hintsTaken: 0,
        }
    );
    const [timeIsUp, setTimeIsUp] = useState(JSON.parse(localStorage.getItem("timeIsUp")) || false);
    const game = JSON.parse(localStorage.getItem("game")) ?? {};
    const user = JSON.parse(localStorage.getItem("user")) ?? {};

    useEffect(() => {
        if (!user.userId) {
            navigate("/");
        }
    }, []);

    // Update localStorage whenever the state variables change
    useEffect(() => {
        localStorage.setItem("activeQuestion", activeQuestion);
    }, [activeQuestion]);

    useEffect(() => {
        localStorage.setItem("showResult", showResult);
    }, [showResult]);

    useEffect(() => {
        localStorage.setItem("result", JSON.stringify(result));
    }, [result]);

    useEffect(() => {
        localStorage.setItem("timeIsUp", timeIsUp);
    }, [timeIsUp]);

    useEffect(() => {
        if (game) fetchQuiz(game.quizId).then((quiz) => setQuestions(quiz.questions));
    }, [game.quizId]);

    const submitQuestion = async (isAnswerCorrect, hintTaken) => {
        const success = await postSubmitQuestion(game.gameId, user.userId, activeQuestion, isAnswerCorrect, hintTaken);
        return success;
    };

    const handleClickNext = async (isAnswerCorrect, hintTaken) => {
        const stillInGame = await submitQuestion(isAnswerCorrect, hintTaken);
        if (!stillInGame) {
            setTimeIsUp(true);
            setShowResult(true);
        } else {
            updateScore(isAnswerCorrect, hintTaken);
            if (activeQuestion < questions.length - 1) {
                setActiveQuestion((prev) => prev + 1);
            } else {
                setActiveQuestion(0);
                setShowResult(true);
            }
        }
    };

    const updateScore = (isAnswerCorrect, hintTaken) => {
        setResult((prev) =>
            isAnswerCorrect
                ? { ...prev, correctAnswers: prev.correctAnswers + 1 }
                : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
        );
        setResult((prev) => (hintTaken ? { ...prev, hintsTaken: prev.hintsTaken + 1 } : { ...prev }));
    };

    if (!questions?.length) return <h5>Loading...</h5>;

    return (
        <div>
            <header className="header" style={{ backgroundColor: "#00BDF2" }}>
                <img style={{ float: "left" }} src={geekGameLogo} alt="Quiz logo"></img>
            </header>
            {user.userId ? (
                <div
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
                >
                    {!showResult ? (
                        <Question question={questions[activeQuestion]} handleClickNext={handleClickNext} />
                    ) : (
                        <Result result={result} timeIsUp={timeIsUp} user={user} />
                    )}
                </div>
            ) : (
                <div className="centered unauthPlaygroud">
                    <h1 style={{ color: "rgb(198, 0, 126)" }}>Oops! :( </h1>
                    <h2 style={{ color: "rgb(137, 12, 88)" }}>You need to login first</h2>
                    <p style={{ color: "rgb(83,86,90)" }}>Please provide entry code to access the quiz</p>

                    <a className="button-27" href="/">
                        Return to Login
                    </a>
                </div>
            )}
        </div>
    );
}
