import styled from "styled-components";
import { Table } from "antd";

const TableContainter = styled.div`
    height: 100%;
    width: 100%;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const StyledSpan = styled.span`
    font-weight: bold;
    font-size: large;
    text-decoration: underline;
    margin: 10px;
`;

const TABLE_COLUMNS = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Points",
        dataIndex: "points",
        key: "points",
    },
    {
        title: "On Question",
        dataIndex: "onQuestion",
        key: "onQuestion",
    },
];

export default function PlayerStatsTable({ players }) {
    players ??= [];
    const dataSource = players
        .map((player, index) => {
            return { key: index, name: player.nickname, points: player.points, onQuestion: player.onQuestion };
        })
        .sort((a, b) => (a.points > b.points ? -1 : 1));
    return (
        <TableContainter>
            <StyledSpan>{`${players.length} Players`}</StyledSpan>
            <Table columns={TABLE_COLUMNS} dataSource={dataSource} scroll={{ y: "40vh" }} />
        </TableContainter>
    );
}
