import styled from "styled-components";
import CardQuestionPreview from "./CardQuestionPreview";

const QuizCardContainer = styled.div`
    height: 400px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    border: 2px solid black;
    border-radius: 10px;
    margin: 10px;
    background-color: seashell;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        box-shadow: 10px 10px 10px lightblue;
    }
`;

const StyledSpan = styled.span`
    font-size: 20px;
    margin-bottom: 30px;
`;

const MiniTextStyledSpan = styled.span`
    font-size: 13px;
    margin-top: 50px;
`;

export default function QuizCard({ quiz, startQuizEdit }) {
    return (
        <QuizCardContainer onClick={() => startQuizEdit(quiz)}>
            <StyledSpan>{quiz.title}</StyledSpan>
            <StyledSpan>{`${quiz.questions.length} Questions`}</StyledSpan>
            <CardQuestionPreview question={quiz.questions[0]} />
            <MiniTextStyledSpan>{`Mongo ID: ${quiz._id}`}</MiniTextStyledSpan>
        </QuizCardContainer>
    );
}
