import React, { useState, useContext, useEffect } from "react";
import { Layout, Button, Modal } from "antd";
import GamesList from "./GamesList";
import { postDeleteGame } from "../../services/gameApi";
import GamesEditor from "./GamesEditor";
import { MessageContext } from "../../contexts/messageContext";

export default function GamesEditMenu() {
    const [editMode, setEditMode] = useState(false);

    const [selectedGame, setSelectedGame] = useState();

    const [showModal, setShowModal] = useState(false);

    const { sendMessage } = useContext(MessageContext);

    const enterCreateMode = () => {
        setSelectedGame(undefined);
        setEditMode(true);
    };

    const deleteGame = async () => {
        setShowModal(false);
        setEditMode(false);
        const success = await postDeleteGame(selectedGame._id);
        if (success) {
            sendMessage("success", "Quiz successfully deleted");
        } else {
            sendMessage("error", "Error in deleting quiz");
        }
        window.location.reload();
    };

    return (
        <Layout>
            <Layout.Header className="admin-sub-menu-header">
                <span className="currentGames">Current Games</span>
                <Button type="default" disabled={editMode} onClick={enterCreateMode}>
                    Create Game
                </Button>
                {editMode ? (
                    <div>
                        <Button type="default" onClick={() => setEditMode(false)}>
                            Go back
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                            type="default"
                            disabled={!selectedGame}
                            onClick={deleteGame}
                            onClick={() => setShowModal(true)}
                            danger
                        >
                            Delete Game
                        </Button>
                    </div>
                ) : (
                    ""
                )}
            </Layout.Header>
            {editMode ? (
                <GamesEditor game={selectedGame} setEditMode={setEditMode}></GamesEditor>
            ) : (
                <GamesList setSelectedGame={setSelectedGame} setEditMode={setEditMode} />
            )}
            <Modal title="Confirm delete" open={showModal} onOk={deleteGame} onCancel={() => setShowModal(false)}>
                <p>Are you sure that you want to delete this quiz?</p>
            </Modal>
        </Layout>
    );
}
