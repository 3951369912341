import styled from "styled-components";

const QuestionPreviewBox = styled.div`
    box-shadow: 0px 0px 4px 4px lightblue;
    border-radius: 10px;
    height: 150px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FlexContainer = styled.div`
    height: 100%;
    width: 200px;
    margin: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StyledSpan = styled.span`
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
`;

const BoldUnderlinedSpan = styled.span`
    text-decoration: underline;
    font-weight: bold;
    font-size: 17px;
`;

export default function CardQuestionPreview({ question }) {
    return (
        <QuestionPreviewBox>
            <BoldUnderlinedSpan>Question 1</BoldUnderlinedSpan>
            <StyledSpan>{question.text}</StyledSpan>
            {question.type === "Closed" ? (
                <FlexContainer>
                    <StyledSpan>A. {question.options[0]}</StyledSpan>
                    <StyledSpan>B. {question.options[1]}</StyledSpan>
                    <StyledSpan>C. {question.options[2]}</StyledSpan>
                    <StyledSpan>D. {question.options[3]}</StyledSpan>
                </FlexContainer>
            ) : (
                <></>
            )}
        </QuestionPreviewBox>
    );
}
