import React, { useState, useContext } from "react";
import { Layout, Button, Modal } from "antd";
import QuizzesList from "./QuizzesList";
import QuizEditor from "./QuizEditor";
import { MessageContext } from "../../contexts/messageContext";
import { postDeleteQuiz } from "../../services/quizApi";

export default function QuizzesMenu() {
    const [editMode, setEditMode] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState();
    const [showModal, setShowModal] = useState(false);
    const { sendMessage } = useContext(MessageContext);

    const deleteQuiz = async () => {
        setShowModal(false);
        setEditMode(false);
        const success = await postDeleteQuiz(selectedQuiz._id);
        if (success) {
            sendMessage("success", "Quiz successfully deleted");
        } else {
            sendMessage("error", "Error in deleting quiz");
        }
        window.location.reload()
    };

    const enterCreateMode = () => {
        setSelectedQuiz(undefined);
        setEditMode(true);
    };
    return (
        <Layout>
            <Layout.Header className="admin-sub-menu-header">
                <span className="currentQuizzez">Current Quizzes</span>
                <Button type="default" disabled={editMode} onClick={enterCreateMode}>
                    Create Quiz
                </Button>
                {editMode ? (
                    <>
                        <Button type="default" onClick={() => setEditMode(false)}>
                            Go back
                        </Button>
                        <Button type="default" disabled={!selectedQuiz} onClick={() => setShowModal(true)} danger>
                            Delete
                        </Button>
                    </>
                ) : (
                    <></>
                )}
            </Layout.Header>
            {editMode ? (
                <QuizEditor quiz={selectedQuiz} setEditMode={setEditMode} />
            ) : (
                <QuizzesList setSelectedQuiz={setSelectedQuiz} setEditMode={setEditMode} />
            )}
            <Modal title="Confirm delete" open={showModal} onOk={deleteQuiz} onCancel={() => setShowModal(false)}>
                <p>Are you sure that you want to delete this quiz?</p>
            </Modal>
        </Layout>
    );
}
