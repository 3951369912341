import React, { useState, useContext, useEffect } from "react";
import { fetchGames } from "../../services/gameApi";
import styled from "styled-components";
import GamesCard from "./GamesCard";

const GamesGrid = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    background-color: white;
    flex-wrap: wrap;
`;

export default function GamesList({ setSelectedGame, setEditMode }) {
    const [games, setGame] = useState([]);

    useEffect(() => {
        fetchGames().then((fetchedgames) => setGame(fetchedgames));
    }, []);

    const startGameEdit = (game) => {
        setSelectedGame(game);
        setEditMode(true);
    };

    return (
        <GamesGrid>
            {games.length ? (
                games.map((game) => <GamesCard key={game._id} game={game} startGameEdit={startGameEdit} />)
            ) : (
                <h5>No games</h5>
            )}
        </GamesGrid>
    );
}
