import { PlusCircleTwoTone } from "@ant-design/icons";
import styled from "styled-components";
import QuestionCardContainer from "./QuestionCardContainer";

const IconWrapper = styled.div`
    cursor: pointer;
    & > * {
        font-size: 100px;
    }
`;

export default function QuestionAddCard({ addQuestion }) {
    return (
        <QuestionCardContainer>
            <IconWrapper onClick={addQuestion}>
                <PlusCircleTwoTone />
            </IconWrapper>
        </QuestionCardContainer>
    );
}
