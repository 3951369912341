import styled from "styled-components";
import { DeleteTwoTone, CheckSquareTwoTone, EditTwoTone } from "@ant-design/icons";
import QuestionCardContainer from "./QuestionCardContainer";
import ClosedQuestionEditor from "./ClosedQuestionEditor";
import OpenQuestionEditor from "./OpenQuestionEditor";
import { Form } from "antd";

const BoldUnderlinedStyledSpan = styled.span`
    font-size: 17px;
    font-weight: bold;
    text-decoration: underline;
    display: block;
`;

const CardHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    @media only screen and (max-width: 450px) {
        width: 360px;
    }
    @media only screen and (max-width: 450px) {
        width: 300px;
    }
`;

export default function QuestionEditCard({ questionIndex, questions, setQuestions, form }) {
    const question = questions[questionIndex];

    const flipQuestionType = () => {
        const { type } = question;
        const targetType = type === "Closed" ? "Open" : "Closed";
        question.type = targetType;
        const newQuestions = [...questions];
        newQuestions[questionIndex].type = targetType;
        setQuestions(newQuestions);
    };

    const deleteQuestion = () => {
        const newQuestions = [...questions];
        newQuestions.splice(questionIndex, 1);
        setQuestions(newQuestions);
    };

    return (
        <QuestionCardContainer style={{ justifyContent: "flex-start" }}>
            <CardHeaderContainer>
                <DeleteTwoTone style={{ marginLeft: "10px", cursor: "pointer" }} onClick={deleteQuestion} />
                <BoldUnderlinedStyledSpan>{`Question ${questionIndex + 1}`}</BoldUnderlinedStyledSpan>
                {question.type === "Closed" ? (
                    <CheckSquareTwoTone style={{ marginRight: "10px", cursor: "pointer" }} onClick={flipQuestionType} />
                ) : (
                    <EditTwoTone style={{ marginRight: "10px", cursor: "pointer" }} onClick={flipQuestionType} />
                )}
            </CardHeaderContainer>
            {question.type === "Closed" ? (
                <ClosedQuestionEditor
                    key={questionIndex}
                    questionIndex={questionIndex}
                    questions={questions}
                    setQuestions={setQuestions}
                    form={form}
                />
            ) : (
                <OpenQuestionEditor
                    key={questionIndex}
                    questionIndex={questionIndex}
                    questions={questions}
                    setQuestions={setQuestions}
                    form={form}
                />
            )}
        </QuestionCardContainer>
    );
}
