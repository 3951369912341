import styled from "styled-components";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { PieChartTwoTone } from "@ant-design/icons";

const GameCardContainer = styled.div`
    height: 400px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    border: 2px solid black;
    border-radius: 10px;
    margin: 10px;
    background-color: #ccffcc;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        box-shadow: 10px 10px 10px lightblue;
    }
`;

const StyledSpan = styled.span`
    font-size: 20px;
    margin-bottom: 30px;
`;

const MiniTextStyledSpan = styled.span`
    font-size: 13px;
    margin-top: 50px;
`;

const IconWrapper = styled.div`
    margin-top: 50px;
    cursor: pointer;
    & > * {
        font-size: 40px;
    }
`;

export default function GamesCard({ game, startGameEdit }) {
    const navigate = useNavigate();

    const AdminDashboardIcon = () => (
        <IconWrapper onClick={() => navigate(`/adminDashboard/gameStats/${game._id}`)}>
            <PieChartTwoTone />
        </IconWrapper>
    );

    return (
        <GameCardContainer onClick={() => startGameEdit(game)}>
            <StyledSpan>{game.name}</StyledSpan>
            <StyledSpan>{`Game Date: ${moment.utc(game.startDate).format("YYYY-MM-DD")}`}</StyledSpan>
            <MiniTextStyledSpan>{`Quiz Id: ${game.quizId}`}</MiniTextStyledSpan>
            <MiniTextStyledSpan>{`Mongo ID: ${game._id}`}</MiniTextStyledSpan>
            <AdminDashboardIcon />
        </GameCardContainer>
    );
}
