import React, { useEffect, useState } from "react";
import styled from "styled-components";

const AnswerInput = styled.input`
    color: #2d264b;
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    padding: 11px;
    margin-top: 15px;
    width: 100%;
`;

export default function OpenQuestionCard({ correctAnswer, setIsAnswerCorrect, setIsAnswerProvided }) {
    const [text, setText] = useState("");
    const correctAnswerPrepared = correctAnswer.toString().toLowerCase().trim();
    const isAnswerNumeric = !isNaN(Number(correctAnswerPrepared));

    useEffect(() => {
        setText("");
    }, [correctAnswer]);

    const handleTextChange = ({ target }) => {
        const answer = target.value.toLowerCase().trim();
        setText(answer);
        setIsAnswerCorrect(answer === correctAnswerPrepared);
        if (answer.length) {
            setIsAnswerProvided(true);
        } else {
            setIsAnswerProvided(false);
        }
    };

    return (
        <AnswerInput
            type={isAnswerNumeric ? "number" : "text"}
            aria-label="Type your answer"
            placeholder="Type your answer"
            value={text}
            onChange={handleTextChange}
        />
    );
}
