import styled from "styled-components";
import QuestionEditCard from "./QuestionEditCard";
import QuestionAddCard from "./QuestionAddCard";

const QuestionsEditorContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export default function QuestionsEditor({ questions, setQuestions, form }) {
    const getEmptyClosedQuestion = () => {
        return {
            text: "",
            imagePath: "",
            imageHeight: 0,
            imageWidth: 0,
            type: "Closed",
            correctAnswer: "0",
            hint: "",
            options: ["", "", "", ""],
        };
    };

    const addQuestion = () => {
        setQuestions((current) => [...current, getEmptyClosedQuestion()]);
    };

    return (
        <QuestionsEditorContainer>
            {questions.map((question, index) => (
                <QuestionEditCard
                    key={index}
                    questionIndex={index}
                    questions={questions}
                    setQuestions={setQuestions}
                    form={form}
                />
            ))}
            <QuestionAddCard addQuestion={addQuestion} />
        </QuestionsEditorContainer>
    );
}
