import styled from "styled-components";
import CountdownClock from "./CountdownClock";
import StatusHeader from "./StatusHeader";

const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 50%;
    align-items: center;
    justify-content: center;
`;

export default function GameProgressTimer({ startedAt, endedAt, status }) {
    return (
        <ProgressBarContainer>
            {status ? (
                <>
                    <StatusHeader status={status} />
                    <CountdownClock startedAt={startedAt} endedAt={endedAt} status={status} />
                </>
            ) : (
                <h5>Loading...</h5>
            )}
        </ProgressBarContainer>
    );
}
