import React from "react";
import { useNavigate } from "react-router-dom";
import "../Home.css";
import { Button, Form, Input, Modal } from "antd";
import "antd/dist/reset.css";
import quizGuy from "../images/cartoonGuy.png";
import AdminLoginService from "../services/AdminLoginService";
import geekGameLogo from "../images/geekGameWithoutTheArc.png";

class AdminLogin extends React.Component {
    adminLoginService = new AdminLoginService();
    render() {
        const { navigation, adminSignIn } = this.props;
        const onFinish = (values) => {
            if (this.adminLoginService.canLogin(values.nickname, values.password)) {
                adminSignIn();
                navigation("../adminDashboard");
            } else {
                error();
            }
        };
        const error = () => {
            Modal.error({
                title: "Incorrect Administrator Credentials...",
                content: "Password or nickname is incorrect or you do not have admin permissions",
            });
        };
        return (
            <div>
                <header className="header">
                    <img style={{ float: "left" }} src={geekGameLogo} alt="Quiz logo"></img>
                    {/* <button
                        className="button-27"
                    >
                        <NavLink style={{ color: "white" }} to="/">
                            Player login
                        </NavLink>
                    </button> */}
                    <a className="button-27" href="/">
                        Player Login
                    </a>
                </header>
                {/* <hr style={{ borderTopColor: "#EEEEEE" }}></hr> */}
                <div className="centered admin-form">
                    <h1 style={{ color: "rgb(0,189,242)", marginTop: "30px", color: "rgb(0, 45, 114)" }}>
                        Admin Login
                    </h1>
                    <img src={quizGuy} className="quizGuy" alt="Cartoon geek guy"></img>
                    <br></br>
                    <Form name="adminLoginForm" style={{ marginBottom: "60px" }} onFinish={onFinish}>
                        <Form.Item
                            name="nickname"
                            id="nickname"
                            rules={[
                                {
                                    required: true,
                                    message: "Nickname is required",
                                },
                                {
                                    pattern: /^[a-zA-Z]{2}\d{5}$/,
                                    message: "Please provide correct nickname",
                                },
                            ]}
                        >
                            <Input placeholder="Nickname" />
                        </Form.Item>
                        <br></br>
                        <Form.Item
                            name="password"
                            id="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Password is required",
                                },
                            ]}
                        >
                            <Input.Password className="ant-input" placeholder="Password" />
                        </Form.Item>
                        <br></br>
                        <Button htmlType="submit" className="button-27" style={{ backgroundColor: "#00BDF2" }}>
                            Login
                        </Button>
                    </Form>
                </div>
            </div>
        );
    }
}

export default function (props) {
    const navigation = useNavigate();
    return <AdminLogin {...props} navigation={navigation} />;
}
