import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Input, Modal } from "antd";
import styled from "styled-components";
import QuestionsEditor from "./QuestionsEditor";
import { postCreateQuiz, postUpdateQuiz } from "../../services/quizApi";
import { MessageContext } from "../../contexts/messageContext";

const FormContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 125px;
`;

export default function QuizEditor({ quiz, setEditMode }) {
    const [title, setTitle] = useState("");
    const [questions, setQuestions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [form] = Form.useForm();
    const { sendMessage } = useContext(MessageContext);

    useEffect(() => {
        if (quiz) {
            setTitle(quiz.title);
            setQuestions(quiz.questions);
            form.setFieldsValue({
                title: quiz.title,
            });
        }
    }, [quiz]);

    const onFormSubmit = () => {
        setShowModal(true);
    };

    const submitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowModal(false);
        if (quiz?.title) {
            updateQuiz(quiz._id, title, questions);
        } else {
            createQuiz(title, questions);
        }
        setEditMode(false);
    };

    const updateQuiz = async (id, title, questions) => {
        const success = await postUpdateQuiz(id, title, questions);
        if (success) {
            sendMessage("success", "Quiz saved successfully");
        } else {
            sendMessage("error", "Error while saving quiz");
        }
    };

    const createQuiz = async (title, questions) => {
        const success = await postCreateQuiz(title, questions);
        if (success) {
            sendMessage("success", "Quiz saved successfully");
        } else {
            sendMessage("error", "Error while saving quiz");
        }
    };

    return (
        <FormContainer>
            <Form form={form} name="quizForm" onFinish={onFormSubmit} layout="horizontal">
                <Form.Item
                    label="Quiz Title"
                    name="title"
                    value={title}
                    rules={[{ required: true, message: "Please input Quiz Title!" }]}
                >
                    <Input style={{ height: "40px" }} onChange={(e) => setTitle(e.target.value)} />
                </Form.Item>
                <QuestionsEditor form={form} questions={questions} setQuestions={setQuestions} />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save Quiz
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                title={`Confirm ${quiz ? "Save" : "Update"}`}
                open={showModal}
                onOk={submitForm}
                onCancel={() => setShowModal(false)}
            >
                <p>Are you sure that you want to save quiz?</p>
            </Modal>
        </FormContainer>
    );
}
