import styled from "styled-components";
import { BulbFilled } from "@ant-design/icons";

const MainContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border: 5px solid #00bfff;
    border-radius: 3px;
`;

const SubContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
`;

const LeftPanel = styled.div`
    height: 100%;
    width: 100px;
`;

const PlayerName = styled.div`
    background-color: #fffaf0;
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
`;

const BottomPanel = styled.div`
    background-color: #fffaf0;
    height: 30px;
    width: 100%;
    display: flex;
`;

const ChartArea = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: white;
`;

const PlayerScoresContainer = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
`;

const PlayerAnswer = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
`;

const QuestionNumber = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid black;
`;

const BottomLeftEmptyPlace = styled.div`
    height: 100%;
    width: 100px !important;
    min-width: 90px;
    background-color: #fffaf0;
`;

export default function GameChart({ players, quiz, status }) {
    const LeftBarPlayerList = () => {
        return players?.map((player) => <PlayerName key={player.nickname}>{player.nickname}</PlayerName>) ?? [];
    };

    const Chart = () => {
        return players?.map((player) => <PlayerScoresContainer>{getPlayerBar(player)}</PlayerScoresContainer>);
    };

    const getPlayerBar = (player) => {
        const playerAnswers = [];
        for (let i = 0; i < player.correctAnswers.length; ++i) {
            const isCorrect = player.correctAnswers[i];
            const hintTaken = player.hintsTaken[i];
            const time = player.timeTakenPerQuestion[i];
            playerAnswers.push(getAnswerTile(isCorrect, hintTaken, time, player.nickname, i));
        }
        for (let i = player.correctAnswers.length; i < quiz?.questions?.length; ++i) {
            playerAnswers.push(getEmptyAnswerTile(player.nickname, i));
        }
        return playerAnswers;
    };

    const getAnswerTile = (isCorrect, hintTaken, time, player, index) => {
        const color = isCorrect ? "green" : "#8B0000";
        return (
            <PlayerAnswer
                key={`${player}${index}`}
                style={{ backgroundColor: color, border: "1px solid #00bfff", color: "white" }}
                title={time}
            >
                {hintTaken ? <BulbFilled /> : <></>}
            </PlayerAnswer>
        );
    };

    const getEmptyAnswerTile = (player, index) => {
        return <PlayerAnswer key={`${player}${index}`} />;
    };

    const getBottomPanel = () => {
        const bottomTiles = [<BottomLeftEmptyPlace />];
        for (let i = 0; i < quiz?.questions?.length; ++i) {
            bottomTiles.push(<QuestionNumber key={`bottomTile${i}`}>{i + 1}</QuestionNumber>);
        }
        return bottomTiles;
    };

    const chartHeight = players ? players.length * 50 : 0;

    return (
        <MainContainer>
            {!status || status === "Pending" ? (
                <h5>Game pending activation...</h5>
            ) : (
                <>
                    <SubContainer>
                        <LeftPanel>{LeftBarPlayerList()}</LeftPanel>
                        <ChartArea style={{ heigth: chartHeight }}>{Chart()}</ChartArea>
                    </SubContainer>
                    <BottomPanel>{getBottomPanel()}</BottomPanel>
                </>
            )}
        </MainContainer>
    );
}
