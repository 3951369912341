import React, { useEffect } from "react";
import { Form, Input, Select, InputNumber } from "antd";
import styled from "styled-components";

const FlexContainer = styled.div`
    display: flex;
`;

export default function ClosedQuestionEditor({ questionIndex, questions, setQuestions, form }) {
    let question = questions[questionIndex];

    useEffect(() => {
        question = questions[questionIndex];
        form.setFieldsValue({
            [`text${questionIndex}`]: question.text,
            [`optionA${questionIndex}`]: question.options[0],
            [`optionB${questionIndex}`]: question.options[1],
            [`optionC${questionIndex}`]: question.options[2],
            [`optionD${questionIndex}`]: question.options[3],
            [`correctOption${questionIndex}`]: question.correctAnswer,
            [`hint${questionIndex}`]: question.hint,
            [`imagePath${questionIndex}`]: question.imagePath,
            [`imageHeight${questionIndex}`]: question.imageHeight,
            [`imageWidth${questionIndex}`]: question.imageWidth,
        });
    }, [questions, questionIndex]);

    const onQuestionFieldChange = (field, value) => {
        question[field] = value;
        questions[questionIndex] = question;
        setQuestions(questions);
    };

    const onAnswerChange = (answerIndex, value) => {
        const answers = [...question.options];
        answers[answerIndex] = value;
        onQuestionFieldChange("options", answers);
    };
    return (
        <div className="closedQuestion">
            <Form.Item
                label="Text"
                name={`text${questionIndex}`}
                value={question.text}
                rules={[{ required: true, message: "Please input Question text!" }]}
            >
                <Input.TextArea
                    onChange={(e) => onQuestionFieldChange("text", e.target.value)}
                />
            </Form.Item>
            <FlexContainer>
                <Form.Item
                    label="A"
                    name={`optionA${questionIndex}`}
                    value={question.options[0]}
                    rules={[{ required: true, message: "Please input answer text!" }]}
                >
                    <Input
                    
                        onChange={(e) => onAnswerChange(0, e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    label="B"
                    name={`optionB${questionIndex}`}
                    value={question.options[1]}
                    rules={[{ required: true, message: "Please input answer text!" }]}
                >
                    <Input
                    
                        onChange={(e) => onAnswerChange(1, e.target.value)}
                    />
                </Form.Item>
            </FlexContainer>
            <FlexContainer>
                <Form.Item
                    label="C"
                    name={`optionC${questionIndex}`}
                    value={question.options[2]}
                    rules={[{ required: true, message: "Please input answer text!" }]}
                >
                    <Input
                    
                        onChange={(e) => onAnswerChange(2, e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    label="D"
                    name={`optionD${questionIndex}`}
                    value={question.options[3]}
                    rules={[{ required: true, message: "Please input answer text!" }]}
                >
                    <Input
                       
                        onChange={(e) => onAnswerChange(3, e.target.value)}
                    />
                </Form.Item>
            </FlexContainer>
            <Form.Item
                label="Correct Option"
                name={`correctOption${questionIndex}`}
                value={question.correctAnswer}
                rules={[{ required: true }]}
            >
                <Select
                    style={{ width: 120 }}
                    options={[
                        { value: "0", label: "A" },
                        { value: "1", label: "B" },
                        { value: "2", label: "C" },
                        { value: "3", label: "D" },
                    ]}
                    onChange={(newValue) => onQuestionFieldChange("correctAnswer", newValue)}
                />
            </Form.Item>
            <Form.Item
                label="Hint"
                name={`hint${questionIndex}`}
                value={question.hint}
                rules={[{ required: true, message: "Please input hint!" }]}
            >
                <Input
             
                    onChange={(e) => onQuestionFieldChange("hint", e.target.value)}
                />
            </Form.Item>
            <hr />
            <Form.Item
                label="Image Path"
                name={`imagePath${questionIndex}`}
                value={question.imagePath}
                style={{ marginTop: -25 }}
            >
                <Input
                  
                    onChange={(e) => onQuestionFieldChange("imagePath", e.target.value)}
                />
            </Form.Item>
            <FlexContainer style={{ marginTop: -20 }}>
                <Form.Item label="Image Height" name={`imageHeight${questionIndex}`} value={question.imageHeight}>
                    <InputNumber
                        style={{ width: "50px", height: "30px", marginRight: "5px" }}
                        onChange={(newValue) => onQuestionFieldChange("imageHeight", newValue)}
                    />
                </Form.Item>
                <Form.Item label="Image Width" name={`imageWidth${questionIndex}`} value={question.imageWidth}>
                    <InputNumber
                        style={{ width: "50px", height: "30px", marginRight: "5px" }}
                        onChange={(newValue) => onQuestionFieldChange("imageWidth", newValue)}
                    />
                </Form.Item>
            </FlexContainer>
        </div>
    );
}
