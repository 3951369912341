import { API_GAME_PATH } from "./apiEndpoints";

const HEADERS = {
    "Content-Type": "application/json",
};

export async function fetchGames() {
    try {
        const response = await fetch(API_GAME_PATH);
        const data = await response.json();
        return data;
    } catch (e) {
        return [];
    }
}

export async function postDeleteGame(id) {
    try {
        const createUrl = `${API_GAME_PATH}/delete/${id}`;
        const response = await fetch(createUrl, {
            method: "POST",
            headers: HEADERS,
        });
        const data = await response.json();
        return true;
    } catch (e) {
        return false;
    }
}

export async function postCreateGame(name, quizId, startTime, allottedTime, entryCode, status) {
    try {
        const createUrl = `${API_GAME_PATH}/create`;
        const body = {
            name: name,
            quizId: quizId,
            startDate: startTime,
            timeAlloted: allottedTime,
            entryCode: entryCode,
            status: status,
        };
        const response = await fetch(createUrl, {
            method: "POST",
            headers: HEADERS,
            body: JSON.stringify(body),
        });
        const data = await response.json();
        return data?.status;
    } catch (e) {
        console.log(e.toString());
        return false;
    }
}

export async function postUpdateGame(id, name, quizId, startTime, allottedTime, entryCode, status) {
    try {
        const createUrl = `${API_GAME_PATH}/update/${id}`;
        const body = {
            name: name,
            quizId: quizId,
            startDate: startTime,
            timeAlloted: allottedTime,
            entryCode: entryCode,
            status: status,
        };
        const response = await fetch(createUrl, {
            method: "POST",
            headers: HEADERS,
            body: JSON.stringify(body),
        });
        const data = await response.json();
        return true;
    } catch (e) {
        console.log(e.toString());
        return false;
    }
}

export async function fetchGameDetails(gameId) {
    try {
        const response = await fetch(`${API_GAME_PATH}/${gameId}`);
        const data = await response.json();
        return data;
    } catch (e) {
        console.log(e.toString());
        return {};
    }
}
export async function postSignPlayerInWithCode(nickname, entryCode) {
    try {
        const createUrl = `${API_GAME_PATH}/signMeIn`;
        const body = {
            code: entryCode,
            nickname: nickname,
        };
        const response = await fetch(createUrl, {
            method: "POST",
            headers: HEADERS,
            body: JSON.stringify(body),
        });
        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (e) {
        console.log(e.toString());
        return false;
    }
}

export async function postSubmitQuestion(gameId, userId, questionNumber, isCorrect, hintTaken) {
    try {
        const createUrl = `${API_GAME_PATH}/${gameId}/${userId}/submitQuestion`;
        const body = {
            questionNumber: questionNumber,
            isCorrect: isCorrect,
            hintTaken: hintTaken,
        };
        const response = await fetch(createUrl, {
            method: "POST",
            headers: HEADERS,
            body: JSON.stringify(body),
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            return true;
        }
    } catch (e) {
        console.log(e.toString());
        return false;
    }
}

export async function activateGame(gameId) {
    try {
        const res = await fetch(`${API_GAME_PATH}/activate/${gameId}`);
    } catch (e) {
        console.log(e.toString());
    }
}

export async function deactivateGame(gameId) {
    try {
        await fetch(`${API_GAME_PATH}/deactivate/${gameId}`);
    } catch (e) {
        console.log(e.toString());
    }
}
