import CryptoJS from "crypto-js";
export default class AdminLoginService {
    adminCreds = [
        { nickname: "mr12345", password: "hstapasstest2023", isAdmin: true },
        { nickname: "ar12345", password: "hstapasstest2023", isAdmin: true },
        { nickname: "aj12345", password: "hstapasstest2023", isAdmin: true },
        { nickname: "hs12345", password: "hstapasstest2023", isAdmin: true },
        { nickname: "ps12345", password: "hstapasstest2023", isAdmin: true },
        { nickname: "js12345", password: "hstapasstest2023", isAdmin: true },
        { nickname: "as12345", password: "hstapasstest2023", isAdmin: true },
    ];

    canLogin = (nickname, password) => {
        return !!this.adminCreds.find((element) => {
            if (element.nickname === nickname && element.password === password && element.isAdmin) {
                localStorage.setItem("adminCanLogin", true);
                const token = this.createToken(element.nickname, element.isAdmin);
                localStorage.setItem("adminToken", token);
                return true;
            }
            return false;
        });
    };

    getToken = () => {
        return localStorage.getItem("adminToken");
    };

    getAdminCanLogin = () => {
        return localStorage.getItem("adminCanLogin");
    };

    base64url = (source) => {
        var encodedSource = CryptoJS.enc.Base64.stringify(source);
        encodedSource = encodedSource.replace(/=+$/, "");
        encodedSource = encodedSource.replace(/\+/g, "-");
        encodedSource = encodedSource.replace(/\//g, "_");
        return encodedSource;
    };

    createToken = (nickname, isAdmin) => {
        var header = {
            alg: "HS256",
            typ: "JWT",
        };
        var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        var encodedHeader = this.base64url(stringifiedHeader);
        var data = {
            nickname: nickname,
            isAdmin: isAdmin,
        };
        var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
        var encodedData = this.base64url(stringifiedData);
        var token = encodedHeader + "." + encodedData;
        return token;
    };

    parseJwt = (token) => {
        try {
            var base64Url = token.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split("")
                    .map(function (c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        } catch (e) {
            return undefined;
        }
    };
}
