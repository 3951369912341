import React from "react";
import "./Footer.css";
import quizLogo from "./images/quizLogo.png";
import geekGameLogo from "./images/geek-game-logo-white.png";
import hstaLogo from "./images/hsta_logo.png";

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="phantom"></div>
                <div className="footer">
                    <img alt="logo footer" src={hstaLogo}></img>

                    <div>
                        <span className="footerSpan" style={{ marginTop: "0px", color: "rgb(0,189,242)" }}>
                            <a
                                href="https://jobs.citi.com/search-jobs/Warsaw%2C%20Mazovia/287/4/798544-858787-6695624-7531926-756135/52x22977/21x01178/50/2"
                                target="_blank"
                                style={{ color: "rgb(0,189,242)" }}
                            >
                                Check Job Opportunities!
                            </a>
                        </span>
                    </div>
                    <div className="footerRight" style={{ color: "white" }}>
                        Created By HSTA Developers<br></br>v2023.2
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
